// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  path: window.location.origin,
  mapBoxToken:
    'pk.eyJ1IjoiYW1hcmlsbGFzYXBwIiwiYSI6ImNsM3QyZ2o4ZzA2NmIzanA0ZHE1YXoxam4ifQ.LG3UgKv8PC1JUq-HwJfTXg',
  production: true,

  // url: 'http://italo:3333',
  // url: 'https://apiqa.amarillasapp.com',
  url: 'https://api.amarillasapp.com',

  phone: '+584149574647',
  email: 'amarillas.app@gmail.com',
  firebaseConfig: {
    apiKey: 'AIzaSyAyYFBtfAjoOmxpBrjs3nf3fMRX3hr9EwU',
    authDomain: 'amarillasapp-f7f48.firebaseapp.com',
    projectId: 'amarillasapp-f7f48',
    storageBucket: 'amarillasapp-f7f48.appspot.com',
    messagingSenderId: '170100981890',
    appId: '1:170100981890:web:c2a009895171a90a05349b',
    vapidKey:
      'BECKkXSrJqfC0EfZBiBvJZKUUk7FBkWrvmGO_kNT4jp18PRmXUe1PFU2nX9znNEzpmzgfFy0n7W7skYBOOuYaeM',
  },
  socialMedia: [
    // {
    //   name: 'pinterest',
    //   url: '#',
    //   img: '/assets/img/icons/logo-pinterest-white.svg',
    // },
    {
      name: 'facebook',
      url: 'https://www.facebook.com/profile.php?id=100080915646096',
      img: '/assets/img/icons/facebook_black_logo_icon_147136.svg',
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/amarillas.app/',
      img: '/assets/img/icons/instagram-seeklogo.svg',
    },
    // {
    //   name: 'youtube',
    //   url: 'https://www.youtube.com/channel/UCbLzdu2-4ZH2DTMeNDfChXw',
    //   img: '/assets/img/icons/YOUTUBE_icon-icons.com_65487.svg',
    // },
    // {
    //   name: 'twitter',
    //   url: '#',
    //   img: '/assets/img/icons/TWITTER_icon-icons.com_62686.svg',
    // },
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
