import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { StorageService } from '../../services/storage.service';
import { Router } from '@angular/router';
import { ToastService } from '../../services/toast.service';
import { NavController } from '@ionic/angular';

const url = environment.url;

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public token: any;
  public userLogin: any;
  public updateHeader = new EventEmitter();
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private toastService: ToastService,
    private navCtrl: NavController
  ) {
    this.getToken();
  }

  async emitUpdate() {
    this.updateHeader.emit();
  }

  getToken() {
    setTimeout(async () => {
      this.token = await this.storageService.get('token');
      this.userLogin = await this.storageService.get('userLogin');
      console.log('this.userLogin :>> ', this.userLogin);
    });
  }

  singUp(data: any) {
    return this.http.post<any>(`${url}/auth/register`, data);
  }

  recovery(data: any) {
    return this.http.post<any>(`${url}/auth/recovery`, data);
  }

  newRecoveryPass(data: any) {
    return this.http.post<any>(`${url}/auth/reset`, data);
  }

  login(data: any) {
    return new Promise((resolve, reject) => {
      this.http.post<any>(`${url}/auth/login`, data).subscribe(
        async (res) => {
          this.saveToken(res?.token?.token);
          this.saveUser(res?.user);
          if (res?.user?.rol_id === 2) {
            this.router.navigate(['/admin/']);
          }
          this.router.navigate(['/login/perfil']);
          resolve(true);
        },
        (err) => {
          if (err?.error?.data) {
            this.toastService.show(err?.error?.data);
          } else {
            this.toastService.show(' Usuario / contraseña incorrectas ');
          }
          reject(err);
        }
      );
    });
  }

  disconnect(tokenPush?) {
    return new Promise((resolve) => {
      this.http
        .get<any>(`${url}/auth/disconnect/${tokenPush}`)
        .subscribe((res) => {
          if (res.status) {
            resolve(true);
          }
        });
    });
  }

  async logOut() {
    const token = await this.storageService.get('token');
    const userLogin = await this.storageService.get('userLogin');
    const tokenPush = await this.storageService.get('tokenPush');
    const cart = await this.storageService.get('cart');
    const disconnected = await this.disconnect(tokenPush);
    const relation = await this.storageService.get('family');
    const family = await this.storageService.get('relation');
    const articleBlog = await this.storageService.get('articleBlog');
    const media = await this.storageService.get('media');

    if (disconnected) {
      if (media) {
        await this.storageService.remove('media');
      }
      if (articleBlog) {
        await this.storageService.remove('articleBlog');
      }
      if (relation) {
        await this.storageService.remove('relation');
      }
      if (family) {
        await this.storageService.remove('family');
      }
      if (cart) {
        await this.storageService.remove('cart');
        localStorage.removeItem('contador');
      }
      if (token) {
        await this.storageService.remove('token');
      }
      if (userLogin) {
        await this.storageService.remove('userLogin');
      }
      this.navCtrl.navigateRoot('/login');
      window.location.reload();
    }
  }

  async saveToken(token: string) {
    await this.storageService.set('token', token);
    this.token = token;
  }
  async saveUser(userLogin: any) {
    await this.storageService.set('userLogin', userLogin);
    this.userLogin = userLogin;
    this.updateHeader.emit();
  }

  editUser(userData: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });
    return this.http.post<any>(`${url}/user/edit`, userData, { headers });
  }

  getUserData() {
    return this.http.get<any>(`${url}/user/index`);
  }

  async deleteAccount() {
    const headers = new HttpHeaders({
      Authorization: `bearer ${this.token}`,
    });
    this.http
      .get(`${url}/auth/destroy`, {
        headers,
      })
      .subscribe(async (x: any) => {
        console.log('x :>> ', x);
        if (x.status) {
          await this.logOut();
        }
      });
  }
}
