import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { Messaging, onMessage, getToken, getMessaging, MessagePayload } from 'firebase/messaging';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { NavController, Platform } from '@ionic/angular';
import { ToastService } from './toast.service';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';

@Injectable({
  providedIn: 'root'
})
export class InitPushNotificationService{
  public msgFirebase: Messaging;
  public tokenUser:string;
  readonly vapidkey = environment.firebaseConfig.vapidKey;
  private messageSource = new Subject();
  public messageObservable = this.messageSource.asObservable();
  
  constructor(
    private platform: Platform,
    private fcm: FCM,
    private toastSv: ToastService,
    private navCtrl: NavController
  ) { 
    this.requestPermission()
    if(!this.platform.is('capacitor')){
      const app = initializeApp(environment.firebaseConfig);
      this.msgFirebase = getMessaging(app);
      onMessage(this.msgFirebase, (payload: MessagePayload)  => {
        this.messageSource.next(payload);
      });
    }
  }

  requestPermission() {
    return new Promise(async (resolve, reject) => {
      if(!this.platform.is('capacitor')){
        const permiss = await Notification.requestPermission();
        if(permiss === 'granted'){
          const tokenFirebase = await getToken(this.msgFirebase, {vapidKey: this.vapidkey});
          this.tokenUser = tokenFirebase;
          resolve(tokenFirebase);
        }else{
          reject(new Error('Acceso denegado'))
        }
      }else{
        this.fcm.getToken().then((token) => {
          console.log('token :>> ', token);
          this.fcm.getInitialPushPayload().then(async (data) => {
            const payload = {
              data
            }
            this.actionsPayload(payload);
          })
          this.tokenUser = token;
          resolve(this.tokenUser);
        });
      }
    })
  }

  startNotificationMobile(app){
    this.fcm.subscribeToTopic('marketing').then((data) => {
      console.log('topic', data);
    })

  

    this.fcm.onNotification().subscribe(async (data) => {
      const payload = {
        data
      }
      if(data.wasTapped){
        this.fcm.getInitialPushPayload().then(async (data) => {
          const payload = {
            data
          }
          this.actionsPayload(payload);
        })
      } else {
        this.actionsPayload(payload);
      };
    }, err => {
      console.error(err);
    });
  }

  async actionsPayload(payload){
    const type: string = payload.data.type;
    let url = '';
    if(type){
      if(type?.toLowerCase() === 'promocion'){
        url = `/market-place/search/1?setPrice=1&viewPromo=1&id=${payload.data.action}`;
      }else if(type?.toLowerCase() === 'expiracion'){
        url = `/login/perfil/directorio`;
      }else if(type.toLocaleLowerCase() === 'comment'){
        url = `/product-detail/${payload.data.action}`;
      }
    }
    if(!this.platform.is('capacitor')){
      if(type){
        this.toastSv.propsPromo = { url };
        if(type?.toLowerCase() === 'promocion'){
          this.toastSv.show(payload.data.description,  'Ver Producto')
        }else if(type?.toLowerCase() === 'expiracion'){
          this.toastSv.show(payload.data.description, 'Ir a Directorios')
        }else if(type.toLocaleLowerCase() === 'comment'){
          this.toastSv.show(payload.data.title, 'Ver comentario');
        }
      }
    }else{
      this.navCtrl.navigateRoot(url);
    }
  }
}
