import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Categories } from 'src/app/generales/login/pages/perfil/interfaces-perfil/interfacesPerfil';
import { LoginService } from 'src/app/generales/login/services/login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminServiceService {
  public url: string = environment.url;
  public emitApprove = new EventEmitter();

  constructor(private http: HttpClient, private loginSv: LoginService) {}

  getShops(pag, pagSyze, search, date, state) {
    return this.http.get(
      `${this.url}/admin/all/${pag}/${pagSyze}/${search}/${date}/${state}`
    );
  }

  getCertify() {
    return this.http.get(`${this.url}/admin/certificate/index`);
  }
  showShop(i) {
    return this.http.get(`${this.url}/admin/showStore/${i}`);
  }
  showUser(i) {
    return this.http.get(`${this.url}/admin/showUser/${i}`);
  }
  showPay(i) {
    return this.http.get(`${this.url}/admin/showPay/${i}`);
  }
  certificate(id) {
    return this.http.post<any>(`${this.url}/admin/certificate/`, id);
  }
  getPayments(pag, pagSyze, search, date, state) {
    return this.http.get(
      `${this.url}/admin/pay/all/${pag}/${pagSyze}/${search}/${date}/${state}`
    );
  }
  changeStatePay(id) {
    return this.http.post(`${this.url}/admin/showPay/`, id);
  }
  showMethod(id) {
    return this.http.get(`${this.url}/admin/showMethod/${id}`);
  }
  approvedMethod(id) {
    return this.http.post(`${this.url}/admin/method/approved`, id);
  }

  getAllCategories(type, params) {
    return new Promise((resolve) => {
      this.http
        .get<Categories>(`${this.url}/categories/${type}`, { params })
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  editCategorie(body) {
    return this.http.post(`${this.url}/categories/update`, body);
  }

  createCategorie(body) {
    return this.http.post(`${this.url}/categories/store`, body);
  }

  deleteCategorie(body) {
    return this.http.post(`${this.url}/categories/delete`, body);
  }

  getCurrencies() {
    return this.http.get(`${this.url}/currency/index`);
  }

  storeCurrencies(body) {
    return this.http.post(`${this.url}/currency/store`, body);
  }

  updateCurrencies(body) {
    return this.http.post(`${this.url}/currency/update`, body);
  }

  deleteCurrencies(body) {
    return this.http.post(`${this.url}/currency/delete`, body);
  }

  // Banner

  allBanner() {
    return this.http.get(`${this.url}/publicity/allBanner`);
  }
  // Crear bancos

  allBank() {
    return this.http.get(`${this.url}/banks/all`);
  }

  storeBank(body) {
    return this.http.post(`${this.url}/banks/store`, body);
  }

  updateBank(body) {
    return this.http.post(`${this.url}/banks/update`, body);
  }

  deleteBank(body) {
    return this.http.post(`${this.url}/banks/delete`, body);
  }

  // All users

  getAllUsers(pag, search = '/') {
    return this.http.get(`${this.url}/admin/indexUser/${pag}/${search}`);
  }

  getAllLocation(pag, search = '/') {
    return this.http.get(`${this.url}/admin/state/index/${pag}`);
  }

  getAllCountry(pag?, search = '/', uri?) {
    return this.http.get(`${this.url}/admin/country/${uri}/${pag}`);
  }

  getAllProducts(pag?, search = '/') {
    return this.http.get(`${this.url}/admin/indexProduct/${pag}/${search}`);
  }

  deleteUser(id) {
    return this.http.post(`${this.url}/admin/user/delete`, id);
  }

  deleteProduct(id) {
    return this.http.post(`${this.url}/admin/product/delete`, id);
  }

  getBalance(id) {
    return this.http.get(`${this.url}/admin/showTransaccion/${id}`);
  }

  getStats(action, pag?, id?, year?, storeId?) {
    const params = {
      pag,
      id,
      year,
      storeId,
    };
    return new Promise<any>((resolve) => {
      this.http
        .get(`${this.url}/metrics/${action}`, {
          params,
        })
        .subscribe((res: any) => {
          resolve(res.data);
        });
    });
  }

  postStats(body) {
    return new Promise<any>((resolve) => {
      this.http
        .post(`${this.url}/metrics/create`, body)
        .subscribe((res: any) => {
          resolve(res.data);
        });
    });
  }

  getReviews(action, pag?, resourceId?, state?) {
    const params = {
      pag,
      resourceId,
      state,
    };
    return new Promise<any>((resolve) => {
      this.http
        .get(`${this.url}/product/add/review/${action}`, {
          params,
        })
        .subscribe((res: any) => {
          resolve(res.data);
        });
    });
  }

  getStores(name) {
    const params = {
      name,
    };
    return new Promise<any>((resolve) => {
      this.http
        .get(`${this.url}/store/minAll`, {
          params,
        })
        .subscribe((res: any) => {
          resolve(res.data);
        });
    });
  }

  postReviews(action, id?) {
    const data = { id };
    return new Promise<any>((resolve) => {
      this.http
        .post(`${this.url}/product/add/review/${action}`, data)
        .subscribe((res: any) => {
          resolve(res.data);
        });
    });
  }

  postLoad(data, typeFile = 'create') {
    return new Promise<any>((resolve, reject) => {
      this.http.post(`${this.url}/load/${typeFile}`, data).subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  postTerms(action, body) {
    return new Promise<any>((resolve) => {
      this.http
        .post(`${this.url}/terms/${action}`, body)
        .subscribe((res: any) => {
          resolve(res);
        });
    });
  }

  getTerms() {
    return new Promise<any>((resolve) => {
      this.http.get(`${this.url}/terms/index`).subscribe((res: any) => {
        resolve(res.data);
      });
    });
  }

  getPrices() {
    return new Promise<any>((resolve) => {
      this.http.get(`${this.url}/plan/admin/index`).subscribe((res: any) => {
        resolve(res.data);
      });
    });
  }

  postPrices(body) {
    return new Promise<any>((resolve) => {
      this.http
        .post(`${this.url}/plan/admin/update`, body)
        .subscribe((res: any) => {
          resolve(res);
        });
    });
  }
  // Create state

  storeState(body, action, uri) {
    return this.http.post(`${this.url}/admin/${uri}/${action}/`, body);
  }

  deleteLocation(body, url, action) {
    return this.http.post(`${this.url}/admin/${url}/${action}`, body);
  }

  getFavorites() {
    return new Promise((resolve) => {
      this.http
        .get(`${this.url}/product/add/favorite/index`)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  downloadTemplateLoad(storeId) {
    return new Promise((resolve) => {
      const auth = `bearer ${this.loginSv.token}`;
      this.http
        .get(`${this.url}/load/index`, {
          params: {
            storeId,
          },
          headers: {
            Authorization: auth,
          },
        })
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  get(url, page, params) {
    return new Promise((resolve) => {
      this.http
        .get(`${this.url}/${url}/${page}`, { params })
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  post(body, uri, action) {
    return new Promise((resolve) => {
      this.http.post(`${this.url}/${uri}/${action}`, body).subscribe((res) => {
        resolve(res);
      });
    });
  }
}
